import React from 'react';
import PropTypes from 'prop-types';
import { handlePagination, handleNextArticle } from '../../hooks'

const LoadMore = ({ current_page, router }) => {
  const s = require('./Load.module.scss');

  const asPath = router.asPath

  const handleClick = (e) => {
    e.preventDefault();
    if(asPath.includes('/article')) {
      handleNextArticle(current_page, router)
    } else {
      handlePagination(current_page, router);
    }
  };

  /**
   * Replace any query keys with values of the query in the pathname
   *
   * @param {*} pathname
   * @param {*} query
   */
  const formatPathnameWithQuery = (pathname, query) => {
    let newPathname = pathname;
    Object.keys(query).forEach((key) => {
      newPathname = newPathname.replace(`[${key}]`, query[key]);
    });
    return newPathname;
  }

  const newPath = `${formatPathnameWithQuery(router.pathname, router.query)}?page=${current_page+1}`

  return (
    <div className={s.LoadMore}>
      <a href={newPath} onClick={handleClick}>Load More</a>
    </div>
  );
};

LoadMore.propTypes = {
  onClick: PropTypes.func
};

export default LoadMore;
